import { toast } from 'react-toastify';

export function checkMinLength(e = null, min) {
  if (e.length < min) {
    return false;
  } else {
    return true;
  }
}

export function checkEmail(e = null) {
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(e);
}

export function maxLength(files, max) {
  if (files.length > max) {
    return false;
  }
  return true;
}

export function isInvalidMimeType(files) {
  let err = false;
  const types = ['text/csv']
  for (var x = 0; x < files.length; x++) {
    for (var e = 0; e < types.length; e++) {
      if (types[e] !== files[x].type) {
        err = files[x].type;
      }
    }
  };
  if (err) {
    return err;
  }
  return false;

}

export function checkMimeType(file, type) {
  if (!file) {
    console.log('file undefined');
    toast.error('Undefined Error Occured');
    return false;
  }
  if (Array.isArray(type)) {
    for (let index = 0; index < type.length; index++) {
      if (file.type === type[index]) {
        return true;
      }
    }
  } else {
    if (file.type !== type) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}

export function checkFileSize(files) {
  let size = 52428800
  let err = false;
  for (var x = 0; x < files.length; x++) {
    if (files[x].size > size) {
      err = files[x].name;
    }
  };
  if (err !== '') {
    return err
  }
  return false;
}
