import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import Moment from "react-moment";
import TextareaAutosize from "react-textarea-autosize";

import {
  getTickets,
  changeTicketStatus,
  sendMessage,
} from "../../inc/actions/adminActions";
import { setInfo } from "../../inc/actions/infoActions";
import Emitter from "./../../inc/emitter";

class TicketDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is404: true,
      ticket: {},
      auth: {},
      inputToggled: false,
    };
    this.props.getTickets();
    this.props.setInfo({
      for: "currentPage",
      data: "tickets",
    });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.id) {
        if (nextProps.data) {
          if (nextProps.data.tickets) {
            let found = false;
            for (
              let index = 0;
              index < nextProps.data.tickets.length;
              index++
            ) {
              let ticket = nextProps.data.tickets[index];
              if (ticket._id === nextProps.id) {
                update.id = nextProps.id;
                update.is404 = false;
                found = true;
                update.ticket = ticket;
              }
            }
            if (!found) {
              update.is404 = true;
              update.id = nextProps.id;
            }
          }
        }
      } else {
        update.is404 = true;
        update.id = nextProps.id;
      }
      if (nextProps.auth) {
        if (!nextProps.auth.isAuthenticated) {
          nextProps.history.push(`/`);
        } else {
          update.auth = nextProps.auth;
        }
      } else {
        nextProps.history.push(`/`);
      }
      return update;
    } else {
      return null;
    }
  }
  showChange = () => {
    this.setState({
      ...this.state,
      inputToggled: true,
    });
  };
  changeStatus = (val) => {
    this.props.changeTicketStatus(this.state.ticket._id, val);
    this.props.getTickets();
    this.setState({
      ...this.state,
      inputToggled: false,
      ticket: {
        ...this.state.ticket,
        status: val,
      },
    });
  };

  componentDidMount() {
    this.scrollToBottom();
    Emitter.on("CHAT_MSG", (msg) => {
      let messages = this.state.ticket.messages;
      messages.push(msg.message);
      this.setState({
        ...this.state,
        ticket: {
          ...this.state.ticket,
          messages,
        },
      });
      this.scrollToBottom();
    });
  }
  componentWillUnmount() {
    Emitter.off("CHAT_MSG");
  }
  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
  };

  addMessage = () => {
    let message = document.querySelector("#newMessageField").value;
    if (!message || message === "") {
      return false;
    }
    this.props.sendMessage(this.state.ticket._id, {
      message,
      from: "admin",
      date: new Date(),
    });
    document.querySelector("#newMessageField").value = "";
  };
  render() {
    let { ticket } = this.state;
    return (
      <>
        {!this.state.is404 &&
          this.state.auth.isAuthenticated &&
          this.state.ticket && (
            <React.Fragment>
              <Container className="ticketWrap">
                <Row>
                  <Col sm={12}>
                    <div
                      className="backToticket"
                      style={{ marginBottom: "20px" }}
                    >
                      <Link to="/tickets/">Back to All tickets</Link>
                    </div>
                  </Col>
                  <Col sm={12} md={8}>
                    <div className="card alignedTable">
                      <div className="cardTitle">Messages</div>
                      <div className="cardContent infoList bg-secondary">
                        <div className="messagesWrap">
                          <div className="messages-inner">
                            <div className="messagesListWrap">
                              <div className="messagesListInner">
                                {ticket.messages && (
                                  <>
                                    {ticket.messages.map((message, index) => [
                                      <div
                                        className={`message ${message.from}`}
                                        key={index}
                                      >
                                        <div className="messageContent">
                                          {message.message}
                                        </div>
                                        <div className="date">
                                          <Moment
                                            format="dddd MMMM Do, YYYY hh:mm A"
                                            date={message.date}
                                          />
                                        </div>
                                      </div>,
                                    ])}
                                    <div
                                      style={{ float: "left", clear: "both" }}
                                      ref={(el) => {
                                        this.messagesEnd = el;
                                      }}
                                    ></div>
                                  </>
                                )}
                                {!ticket.messages && (
                                  <>
                                    <div className="nomessage">
                                      Write a message to start the conversation
                                    </div>
                                  </>
                                )}
                                {ticket.messages && ticket.messages.length < 1 && (
                                  <>
                                    <div className="nomessage">
                                      Write a message to start the conversation
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="messageFormWrap">
                              <TextareaAutosize
                                name="message"
                                cols="30"
                                rows="50"
                                placeholder="Write your message here"
                                className="form-control textarea"
                                id="newMessageField"
                                onBlur={(e) =>
                                  this.setState({
                                    ...this.state,
                                    newmessage: e.target.value,
                                  })
                                }
                              />
                              <button
                                className="button"
                                onClick={this.addMessage}
                              >
                                Send
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={12} md={4}>
                    <div className="card alignedTable">
                      <div className="cardTitle">Ticket Details</div>
                      <div className="cardContent infoList withPadding bg-secondary">
                        <ul className="contentList">
                          <li>
                            <div className="label">Type</div>
                            <div className={`value`}>
                              {this.state.ticket.type}
                            </div>
                          </li>
                          {this.state.ticket.type === "lessons" && (
                            <li>
                              <div className="label">Lesson</div>
                              <div className={`value`}>
                                <Link
                                  to={`/booking/${this.state.ticket.element}`}
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    background: "transparent",
                                    display: "inline-block",
                                  }}
                                >
                                  {this.state.ticket.element}
                                </Link>
                              </div>
                            </li>
                          )}
                          {this.state.ticket.type === "orders" && (
                            <li>
                              <div className="label">Orders</div>
                              <div className={`value`}>
                                <Link
                                  to={`/order/${this.state.ticket.element}`}
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    background: "transparent",
                                    display: "inline-block",
                                  }}
                                >
                                  {this.state.ticket.element}
                                </Link>
                              </div>
                            </li>
                          )}
                          <li>
                            <div className="label">Date</div>
                            <div className={`value`}>
                              <Moment format="DD/MM/YYYY">
                                {this.state.ticket.date}
                              </Moment>
                            </div>
                          </li>
                          <li>
                            <div className="label">Status</div>
                            {!this.state.inputToggled && (
                              <div
                                className={`value ${
                                  this.state.ticket.status === "In Progress" ||
                                  this.state.ticket.status ===
                                    "Awaiting User Reply"
                                    ? "text-info"
                                    : this.state.ticket.status ===
                                      "Awaiting Admin Reply"
                                    ? "text-danger"
                                    : "text-success"
                                }`}
                              >
                                {this.state.ticket.status}
                                <span
                                  className="color-accent"
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "8px",
                                  }}
                                  onClick={this.showChange}
                                >
                                  Change
                                </span>
                              </div>
                            )}
                            {this.state.inputToggled && (
                              <div className={`value`}>
                                <select
                                  onChange={(e) =>
                                    this.changeStatus(e.target.value)
                                  }
                                  className="form-control"
                                  defaultValue={this.state.ticket.status}
                                >
                                  <option value="In Progress">
                                    In Progress
                                  </option>
                                  <option value="Resolved">Resolved</option>
                                  <option value="Awaiting User Reply">
                                    Awaiting User Reply
                                  </option>
                                </select>
                              </div>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </React.Fragment>
          )}
        {this.state.is404 && (
          <>
            <h1 className="text-center">404 :(</h1>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.data,
});

TicketDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  getTickets: PropTypes.func.isRequired,
  changeTicketStatus: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  getTickets,
  changeTicketStatus,
  setInfo,
  sendMessage,
})(withRouter(TicketDetails));
