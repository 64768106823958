import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./reducers";

const middleWare = [thunk];
const initialState = {};

let devTools =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
    : null;

// --- production-- -
const store = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(...middleWare))
);

// --- dev ---
// const store = createStore(
//   rootReducer,
//   initialState,
//   compose(applyMiddleware(...middleWare), devTools)
// );

export default store;
