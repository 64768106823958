import { SET_USER, SET_ERROR, UNSET_ERROR } from "../types";
import axios from "axios";
import setAuthHeader from "../setAuthHeader";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

// export const registerUser = (
//   userData,
//   history,
//   returnto = "/"
// ) => dispatch => {
//   axios
//     .post(process.env.REACT_APP_API_PATH + "auth/register", userData)
//     .then(res => {
//       dispatch(setUser(res.data.token, "registeration"));
//       toast.success("Your Equireel ID has been created");
//       history.push(returnto);
//     })
//     .catch(err => {
//       dispatch({
//         type: SET_ERROR,
//         payload: {
//           for: "registerationError",
//           msg: err.response.data.error
//         }
//       });
//     });
// };

export const loginUser = (userData, history) => dispatch => {
  axios
    .post(process.env.REACT_APP_API_PATH + "auth/login", userData)
    .then(res => {
      dispatch(setUser(res.data.token, "login"));
      toast.success("Logged In");
      history.push("/");
    })
    .catch(err => {
      let e = typeof err.response !== 'undefined' ? err.response.data.error : "undefined error";
      if (!e) {
        e = "undefined error";
      }
      dispatch({
        type: SET_ERROR,
        payload: {
          for: "loginError",
          msg: e
        }
      });
    });
};

export const setUser = (userData, calledFrom) => dispatch => {
  localStorage.setItem("jwtToken", userData);
  setAuthHeader(userData);
  let decoded;
  try {
    decoded = jwt_decode(userData);
  } catch (err) {
    decoded = "";
    if (calledFrom === "login" || calledFrom === "registeration") {
      dispatch({
        type: SET_ERROR,
        payload: {
          for: calledFrom + "Error",
          msg: "unexpected error. please contact support."
        }
      });
    }
  }
  dispatch({
    type: SET_USER,
    payload: decoded
  });
  if (calledFrom === "login" || calledFrom === "registeration") {
    dispatch({
      type: UNSET_ERROR,
      payload: "loginError"
    });
  }
};

export const verifyThenSetUser = () => dispatch => {
  const token = localStorage.getItem("jwtToken");
  if (token) {
    let decoded;
    try {
      decoded = jwt_decode(token);
    } catch (err) {
      decoded = "";
      console.log("error decoding token", err, token);
      alert(
        "1. Something unexpected happened. please contact support and explain what you did. Thanks for understanding."
      );
    }
    if (!decoded._id) {
      console.log("returning false 1", decoded);
      return false;
    }
    axios
      .post(process.env.REACT_APP_API_PATH + "auth/verifyUserData", decoded)
      .then(res => {
        if (res.data.token) {
          dispatch(setUser(res.data.token, "verifyAndSetUser"));
          return "true";
        } else {
          toast.warning('Please Login Again');
          console.log("returning false 2");
          return false;
        }
      })
      .catch(err => {
        toast.warning('Please Login Again');
        console.log(err);
        console.log("returning false 3");
        return false;
      });
  } else {
    console.log("returning false 4");
    return false;
  }
};

export const unsetUser = redirect => dispatch => {
  localStorage.removeItem("jwtToken");
  setAuthHeader();
  if (redirect) {
    window.location.href = redirect;
  }
};
