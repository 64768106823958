import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from "react-router-dom";
import { Provider } from "react-redux";

import store from "./inc/store";
import { verifyThenSetUser } from "./inc/actions/authActions";
import { ToastContainer, toast } from "react-toastify";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/style/App.css";
import "react-toastify/dist/ReactToastify.css";

import Header from "./views/inc/Header";
import Footer from "./views/inc/Footer";
import Login from "./views/authentication/Login";
import ImportRecords from "./views/actions/importRecords";
import OrdersList from "./views/actions/OrdersList";
import OrderDetails from "./views/actions/OrderDetails";
import CreatePromo from "./views/actions/CreatePromo";
import PromoList from "./views/actions/PromoList";
import UsersList from "./views/actions/UsersList";
import AdminUsersList from "./views/actions/AdminUsersList";
import CreateAdmin from "./views/actions/CreateAdmin";
import CreateCoach from "./views/actions/CreateCoach";
import CoachList from "./views/actions/CoachList";
import LessonsList from "./views/actions/LessonsList";
import LessonDetails from "./views/actions/LessonDetails";
import TicketList from "./views/actions/TicketList";
import TicketDetails from "./views/actions/TicketDetails";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  componentDidMount() {
    store.dispatch(verifyThenSetUser());
    setTimeout(() => this.setState({ loading: false }), 1500);
  }

  render() {
    const { loading } = this.state;
    if (loading) {
      return null;
    }
    const ShowOrder = () => {
      let { id } = useParams();

      return <OrderDetails id={id} />;
    };
    const ShowLesson = () => {
      let { id } = useParams();

      return <LessonDetails id={id} />;
    };
    const ShowTicket = () => {
      let { id } = useParams();

      return <TicketDetails id={id} />;
    };
    return (
      <>
        <Provider store={store}>
          <Router>
            <div className="App">
              <Header />
              <main>
                <Switch>
                  <Route path="/" exact component={Login} />
                  <Route path="/import" exact component={ImportRecords} />
                  <Route path="/orders" exact component={OrdersList} />
                  <Route path="/promos/create" exact component={CreatePromo} />
                  <Route path="/promos" exact component={PromoList} />
                  <Route path="/customers" exact component={UsersList} />
                  <Route path="/admins" exact component={AdminUsersList} />
                  <Route path="/admins/create" exact component={CreateAdmin} />
                  <Route path="/coaches" exact component={CoachList} />
                  <Route path="/coaches/create" exact component={CreateCoach} />
                  <Route path="/bookings" exact component={LessonsList} />
                  <Route path="/tickets" exact component={TicketList} />
                  <Route path="/order/:id" children={<ShowOrder />} />
                  <Route path="/booking/:id" children={<ShowLesson />} />
                  <Route path="/ticket/:id" children={<ShowTicket />} />
                </Switch>
              </main>
              <Footer />
            </div>
          </Router>
        </Provider>
        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
      </>
    );
  }
}

export default App;
