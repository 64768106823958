import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { toast } from "react-toastify";

import { checkMimeType } from "../../inc/Validation";
import { setError, unsetError } from "../../inc/actions/errorActions";
import { importCSV } from "../../inc/actions/adminActions";
import store from "../../inc/store";
import { setInfo } from "../../inc/actions/infoActions";

class ImportRecords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      auth: "",
      selectedFile: null,
    };
    this.props.setInfo({
      for: "currentPage",
      data: "import",
    });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (!nextProps.auth.isAuthenticated) {
      nextProps.history.push("/");
    }
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.errors.importCSVError !== prevProps.error) {
        update.error = nextProps.errors.importCSVError;
      }
      return update;
    } else {
      return null;
    }
  }

  changeHandler = (e, verify = false) => {
    this.setState({ [e.target.name]: e.target.value });
    if (verify) {
      this.verifyField(e);
    }
  };

  submitHandler = (e) => {
    e.preventDefault();
    const form = e.target;
    const formId = form.id;
    if (!formId) {
      console.log("form id not found", form);
      toast.error("An Unexpected Error Occured");
      return false;
    }
    switch (formId) {
      case "csvForm": {
        const data = new FormData();
        var file = this.state.selectedFile;
        if (!file || file === null) {
          toast.error("No CSV file is selected");
          break;
        }
        data.append("file", file);
        this.unsetErrorForThis();
        store.dispatch(importCSV(data, this.props.history));
        break;
      }
      default: {
        console.log("unknown form");
        break;
      }
    }
  };
  unsetErrorForThis = () => {
    store.dispatch(unsetError("importCSVError"));
    this.setState(() => {
      return {
        error: {},
      };
    });
  };
  errorAlert = (e) => {
    if (this.state.error) {
      if (typeof this.state.error[e] !== "undefined") {
        if (this.state.error[e] !== "") {
          return <p className="text-danger">{this.state.error[e]}</p>;
        }
      }
    }
  };

  fileChangeHandler = (event) => {
    var files = event.target.files;
    var file = files[0];
    let fineMimeType = checkMimeType(file, [
      "text/csv",
      "application/vnd.ms-excel",
    ]);
    if (fineMimeType) {
      var html = "";
      if (files.length === 0) {
        html = "<p>No File Selected</p>";
      } else {
        html = `<p>Selected File: ${file.name}</p>`;
      }
      document.querySelector(".fileList").innerHTML = html;
      this.unsetErrorForThis();
      this.setState({
        ...this.state,
        selectedFile: file,
        loaded: 0,
      });
    } else {
      event.target.value = null;
      store.dispatch(
        setError({
          for: "importCSVError",
          msg: {
            csv: `${file.name} is invalid file type`,
          },
        })
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <Container className="importCSV">
          <Row>
            <Col>
              <div className="pageTitle">
                <h2>Import Records</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="card">
                <div className="cardContent withPadding">
                  <form
                    id="csvForm"
                    onSubmit={this.submitHandler}
                    encType="multipart/form-data"
                  >
                    <div className="fieldWrap">
                      <label>Select your CSV File</label>
                      <div className="form-group files">
                        <input
                          type="file"
                          id="files"
                          name="files"
                          onChange={this.fileChangeHandler}
                          className="form-control"
                          accept=".csv"
                        />
                      </div>
                      <div className="fileList"></div>
                      <div className="errorContainer">
                        {this.errorAlert("csv")}
                      </div>
                      <div className="infoContainer">
                        Note: Make Sure the file is in CSV format
                      </div>
                    </div>
                    <div className="fieldWrap">
                      <input
                        type="submit"
                        value="Import"
                        className="text submit"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  posts: state.posts,
});

ImportRecords.propTypes = {
  importCSV: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { importCSV, setInfo })(
  withRouter(ImportRecords)
);
