import { SET_ERROR, UNSET_ERROR } from "../types";

export const setError = errorData => {
  return {
    type: SET_ERROR,
    payload: {
      for: errorData.for,
      msg: errorData.msg
    }
  };
};

export const unsetError = error => {
  return {
    type: UNSET_ERROR,
    payload: error
  };
};
