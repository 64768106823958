import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import Moment from "react-moment";

import { getOrders, changeOrderStatus } from "../../inc/actions/adminActions";
import { setInfo } from "../../inc/actions/infoActions";

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is404: true,
      order: {},
      auth: {},
      inputToggled: false,
    };
    this.props.getOrders();
    this.props.setInfo({
      for: "currentPage",
      data: "orders",
    });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.id) {
        if (nextProps.data) {
          if (nextProps.data.orders) {
            let found = false;
            for (let index = 0; index < nextProps.data.orders.length; index++) {
              let order = nextProps.data.orders[index];
              if (order._id === nextProps.id) {
                update.id = nextProps.id;
                update.is404 = false;
                found = true;
                update.order = order;
              }
            }
            if (!found) {
              update.is404 = true;
              update.id = nextProps.id;
            }
          }
        }
      } else {
        update.is404 = true;
        update.id = nextProps.id;
      }
      if (nextProps.auth) {
        if (!nextProps.auth.isAuthenticated) {
          nextProps.history.push(`/`);
        } else {
          update.auth = nextProps.auth;
        }
      } else {
        nextProps.history.push(`/`);
      }
      return update;
    } else {
      return null;
    }
  }
  showChange = () => {
    this.setState({
      ...this.state,
      inputToggled: true,
    });
  };
  changeStatus = (val) => {
    this.props.changeOrderStatus(this.state.order._id, val);
    this.props.getOrders();
    this.setState({
      ...this.state,
      inputToggled: false,
    });
  };
  render() {
    return (
      <>
        {!this.state.is404 &&
          this.state.auth.isAuthenticated &&
          this.state.order && (
            <React.Fragment>
              <Container className="orderWrap">
                <Row>
                  <Col sm={12}>
                    <div className="backToOrder">
                      <Link to="/orders/">Back to All Orders</Link>
                    </div>
                  </Col>
                  <Col sm={12} md={8}>
                    <div className="card cart-table">
                      <div className="cardTitle">Order Items</div>
                      <div className="cardContent bg-secondary">
                        <table className="table table-responsive color-text">
                          <tbody>
                            {this.state.order.cart.map((item) => (
                              <tr key={this.state.order.cart.indexOf(item)}>
                                <td>
                                  <ul>
                                    {Object.entries(item).map((val, key) => [
                                      val[0] !== "Price" && val[0] !== "Horse" && (
                                        <li key={key}>
                                          <span className="label">
                                            {val[0] + ":"}
                                          </span>
                                          <span className="value">
                                            {val[1] ? val[1] : "N/A"}
                                          </span>
                                        </li>
                                      ),
                                    ])}
                                  </ul>
                                </td>
                                <td>
                                  <span className="price">
                                    <span className="value">{item.Price}</span>
                                    <span className="currency">
                                      {this.state.order.currency}
                                    </span>
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Col>
                  <Col sm={12} md={4}>
                    <div className="card alignedTable">
                      <div className="cardTitle">Order Details</div>
                      <div className="cardContent infoList withPadding bg-secondary">
                        <ul className="contentList">
                          <li>
                            <div className="label">Charged</div>
                            <div className="value">
                              <div className="price">
                                <span className="value">
                                  {this.state.order.charged.toFixed(2)}
                                </span>
                                <span className="currency">
                                  {this.state.order.currency}
                                </span>
                              </div>
                            </div>
                          </li>
                          {this.state.order.promo && (
                            <li>
                              <div className="label">Promo Discount</div>
                              <div className="value">
                                {this.state.order.promo.code}
                              </div>
                            </li>
                          )}
                          <li>
                            <div className="label">Date</div>
                            <div className={`value`}>
                              <Moment format="DD/MM/YYYY">
                                {this.state.order.date}
                              </Moment>
                            </div>
                          </li>
                          <li>
                            <div className="label">Status</div>
                            {!this.state.inputToggled && (
                              <div
                                className={`value ${
                                  this.state.order.status === "Processing"
                                    ? "text-warning"
                                    : this.state.order.status === "Completed"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {this.state.order.status}
                                <span
                                  className="color-accent"
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "8px",
                                  }}
                                  onClick={this.showChange}
                                >
                                  Change
                                </span>
                              </div>
                            )}
                            {this.state.inputToggled && (
                              <div className={`value`}>
                                <select
                                  onChange={(e) =>
                                    this.changeStatus(e.target.value)
                                  }
                                  className="form-control"
                                  defaultValue={this.state.order.status}
                                >
                                  <option value="Processing">Processing</option>
                                  <option value="Completed">Completed</option>
                                  <option value="Cancelled">Cancelled</option>
                                </select>
                              </div>
                            )}
                          </li>
                          <li>
                            <div className="label">Social Share Consent</div>
                            <div className={`value`}>
                              {this.state.order.shareconsent ? "Yes" : "No"}
                            </div>
                          </li>
                          <li>
                            <div className="label">
                              Include Faults (if incurred)
                            </div>
                            <div className={`value`}>
                              {this.state.order.includefaults}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card alignedTable">
                      <div className="cardTitle">Customer Details</div>
                      <div className="cardContent infoList withPadding bg-secondary">
                        <ul className="contentList">
                          <li>
                            <div className="label">First Name</div>
                            <div className="value">
                              {this.state.order.user.fname}
                            </div>
                          </li>
                          <li>
                            <div className="label">Last Name</div>
                            <div className="value">
                              {this.state.order.user.lname}
                            </div>
                          </li>
                          <li>
                            <div className="label">Email</div>
                            <div className={`value`}>
                              {this.state.order.user.email}
                            </div>
                          </li>
                          <li>
                            <div className="label">Phone</div>
                            <div className={`value`}>
                              {this.state.order.user.phone}
                            </div>
                          </li>
                          <li>
                            <div className="label">Address</div>
                            <div className="value">
                              {this.state.order.user.address1}
                              <br />
                              {this.state.order.user.address2
                                ? this.state.order.user.address2
                                : ""}
                              {this.state.order.user.address2 && <br />}
                              City: {this.state.order.user.city}
                              <br />
                              State: {this.state.order.user.state}
                              <br />
                              Country: {this.state.order.user.country}
                              <br />
                              Postal Code: {this.state.order.user.postalcode}
                              <br />
                            </div>
                          </li>
                          <li>
                            <div className="label">ID</div>
                            <div className="value">
                              {this.state.order.user._id}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </React.Fragment>
          )}
        {this.state.is404 && (
          <>
            <h1 className="text-center">404 :(</h1>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.data,
});

OrderDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  getOrders: PropTypes.func.isRequired,
  changeOrderStatus: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  getOrders,
  changeOrderStatus,
  setInfo,
})(withRouter(OrderDetails));
