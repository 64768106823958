import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import DataTable from "react-data-table-component";
import Moment from "react-moment";

import { getPromos, deletedata } from "../../inc/actions/adminActions";
import { setInfo } from "../../inc/actions/infoActions";

class PromoList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promos: [],
      auth: "",
    };
    this.props.getPromos();
    this.props.setInfo({
      for: "currentPage",
      data: "promos",
    });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (!nextProps.auth.isAuthenticated) {
      nextProps.history.push("/");
    }
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.data) {
        if (nextProps.data.promos) {
          update.promos = nextProps.data.promos;
        }
      }
      return update;
    } else {
      return null;
    }
  }
  errorAlert = (e) => {
    if (this.state.error) {
      if (typeof this.state.error[e] !== "undefined") {
        if (this.state.error[e] !== "") {
          return <p className="text-danger">{this.state.error[e]}</p>;
        }
      }
    }
  };

  deleteit = (id) => {
    let confirmed = window.confirm(
      "Are you sure you want to delete this Promo?"
    );
    if (confirmed) {
      this.props.deletedata("promos", id);
    }
    return false;
  };

  render() {
    const columns = [
      {
        name: "Created On",
        selector: "createdOn",
        sortable: true,
        cell: (row) => <Moment>{row.createdOn}</Moment>,
      },
      {
        name: "Code",
        selector: "code",
        sortable: true,
      },
      {
        name: "Type",
        selector: "type",
        sortable: true,
      },
      {
        name: "Amount",
        selector: "amount",
        sortable: true,
      },
      {
        name: "Usage Left",
        selector: "usageleft",
        sortable: true,
        cell: (row) => (row.usageleft === -1 ? "Unlimited" : row.usageleft),
      },
      {
        name: "Expiry",
        selector: "expiryOn",
        sortable: true,
        cell: (row) => <Moment>{row.expiryOn}</Moment>,
      },
      {
        name: "Actions",
        cell: (row) => (
          <>
            <span
              style={{ color: "var(--accent-color)", cursor: "pointer" }}
              onClick={() => {
                this.deleteit(row._id);
                return false;
              }}
            >
              Delete Promo
            </span>
          </>
        ),
        grow: 2,
      },
    ];
    return (
      <React.Fragment>
        <Container className="ordersList">
          <Row>
            <Col>
              <div className="pageTitle">
                <h2>Promo Codes</h2>
                <div className="cta">
                  <Link
                    to="/promos/create/"
                    className="button bg-secondary color-text"
                  >
                    Create Promo
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable
                data={this.state.promos}
                columns={columns}
                theme="dark"
                pagination={true}
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.data,
});

PromoList.propTypes = {
  auth: PropTypes.object.isRequired,
  getPromos: PropTypes.func.isRequired,
  deletedata: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { getPromos, deletedata, setInfo })(
  withRouter(PromoList)
);
