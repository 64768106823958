import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { toast } from "react-toastify";
import TextAreaAutosize from "react-textarea-autosize";
import { setInfo } from "../../inc/actions/infoActions";

import { createCoach } from "../../inc/actions/adminActions";
import { checkMinLength, checkEmail } from "../../inc/Validation";

class CreateCoach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      fname: "",
      lname: "",
      bio: "",
      fee: null,
      dp: null,
    };
    this.props.setInfo({
      for: "currentPage",
      data: "coaches",
    });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.auth) {
        if (!nextProps.auth.isAuthenticated) {
          nextProps.history.push(`/`);
        } else {
          update.auth = nextProps.auth;
        }
      } else {
        nextProps.history.push(`/`);
      }
      return update;
    } else {
      return null;
    }
  }
  changeHandler = (e) => {
    let { name, value } = e.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  validate = () => {
    var errorMsg = "";
    const { email, fname, lname, bio, fee, dp } = this.state;
    if (!fname || !checkMinLength(fname, 4)) {
      errorMsg = "First Name should be atleast 4 characters long";
      toast.error(errorMsg);
      return false;
    }
    if (!lname || !checkMinLength(lname, 1)) {
      errorMsg = "Last Name should be atleast 1 character long";
      toast.error(errorMsg);
      return false;
    }
    if (!email || !checkEmail(email)) {
      errorMsg = "Invalid email";
      toast.error(errorMsg);
      return false;
    }
    if (!fee || Number(fee) < 1) {
      errorMsg =
        "fee is required and should be more than 0. coaches can always change it later.";
      toast.error(errorMsg);
      return false;
    }
    if (!dp) {
      errorMsg = "Display Picture is required";
      toast.error(errorMsg);
      return false;
    }
    return true;
  };

  submitHandler = (e) => {
    e.preventDefault();
    const { email, fname, lname, bio, fee } = this.state;
    if (this.validate()) {
      const data = new FormData();
      data.append("email", this.state.email);
      data.append("fname", this.state.fname);
      data.append("lname", this.state.lname);
      data.append("bio", this.state.bio);
      data.append("fee", this.state.fee);
      data.append("dp", this.state.dp);

      this.props.createCoach(data, this.props.history);
    } else {
      return false;
    }
  };
  checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    const types = ["image/jpeg"];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      alert(err);
      return false;
    }
    return true;
  };
  FilechangeHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (this.checkMimeType(e)) {
        this.setState({
          ...this.state,
          dp: file,
        });
      }
    } else {
      this.setState({
        ...this.state,
        dp: null,
        loaded: 0,
      });
    }
  };

  render() {
    return (
      <Container className="createCoachWrap">
        <Row>
          <Col>
            <div className="pageTitle">
              <h2>Create Coach</h2>
              <div className="cta">
                <Link to="/coaches" className="button bg-secondary color-text">
                  Back to all Coaches
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="card">
              <div className="cardContent withPadding">
                <form action="post" onSubmit={this.submitHandler}>
                  <label htmlFor="dp">Profile Photo</label>
                  <input
                    onChange={this.FilechangeHandler}
                    name="dp"
                    className="form-control dp"
                    type="file"
                  />
                  <p>
                    Please make sure the image is of 1:1 ratio (square), is in
                    jpeg format and is atleast 300px wide
                  </p>
                  <input
                    onChange={this.changeHandler}
                    name="fname"
                    className="form-control fname"
                    type="text"
                    placeholder="First Name"
                  />
                  <input
                    onChange={this.changeHandler}
                    name="lname"
                    className="form-control lname"
                    type="text"
                    placeholder="Last Name"
                  />
                  <TextAreaAutosize
                    onChange={this.changeHandler}
                    name="bio"
                    className="form-control bio"
                    type="text"
                    placeholder="Bio"
                  />
                  <input
                    onChange={this.changeHandler}
                    name="email"
                    className="form-control email"
                    type="text"
                    placeholder="Email"
                  />
                  <input
                    onChange={this.changeHandler}
                    name="fee"
                    className="form-control fee"
                    type="number"
                    step="0.01"
                    placeholder="Lesson Fee"
                  />
                  <input
                    className="button bg-accent"
                    type="submit"
                    value="Submit"
                  />
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

CreateCoach.propTypes = {
  auth: PropTypes.object.isRequired,
  createCoach: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { createCoach, setInfo })(
  withRouter(CreateCoach)
);
