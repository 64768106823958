import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorsReducer from "./errorsReducer";
import dataReducer from "./dataReducer";
import infoReducer from "./infoReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorsReducer,
  data: dataReducer,
  info: infoReducer,
});
