import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import DataTable from "react-data-table-component";
import Moment from "react-moment";
import styled from "styled-components";
import { setInfo } from "../../inc/actions/infoActions";

import { getLessons, deletedata } from "../../inc/actions/adminActions";

class LessonsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lessons: [],
      auth: "",
    };
    this.props.getLessons();
    this.props.setInfo({
      for: "currentPage",
      data: "bookings",
    });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (!nextProps.auth.isAuthenticated) {
      nextProps.history.push("/");
    }
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.data) {
        if (nextProps.data.lessons) {
          update.lessons = nextProps.data.lessons;
        }
      }
      return update;
    } else {
      return null;
    }
  }
  errorAlert = (e) => {
    if (this.state.error) {
      if (typeof this.state.error[e] !== "undefined") {
        if (this.state.error[e] !== "") {
          return <p className="text-danger">{this.state.error[e]}</p>;
        }
      }
    }
  };
  deleteit = (id) => {
    let confirmed = window.confirm(
      "Are you sure you want to delete this lesson?"
    );
    if (confirmed) {
      this.props.deletedata("lessons", id);
    }
    return false;
  };

  render() {
    const { lessons } = this.state;
    function convertArrayOfObjectsToCSV(array) {
      let result;

      const columnDelimiter = ",";
      const lineDelimiter = "\n";
      const keys = [
        "_id",
        "status",
        "user",
        "coach",
        "bookingslot",
        "files",
        "currency",
        "charged",
        "date",
      ];

      result = "";
      result += keys.join(columnDelimiter);
      result += lineDelimiter;

      array.forEach((item) => {
        let ctr = 0;
        keys.forEach((key) => {
          if (ctr > 0) result += columnDelimiter;

          result +=
            typeof item[key] === "object" || typeof item[key] === "array"
              ? JSON.stringify(item[key])
                  .replace(/{/g, "")
                  .replace(/"/g, " ")
                  .replace(/,/g, " | ")
                  .replace(/#/g, " ")
                  .replace(/&/g, "and")
              : typeof item[key] === "string"
              ? String(item[key])
                  .replace(/{/g, "")
                  .replace(/"/g, " ")
                  .replace(/,/g, " | ")
                  .replace(/#/g, " ")
                  .replace(/&/g, "and")
              : item[key];

          ctr++;
        });
        result += lineDelimiter;
      });

      return result;
    }
    function downloadCSV(array) {
      const link = document.createElement("a");
      let csv = convertArrayOfObjectsToCSV(array);
      if (csv == null) return;

      const filename = "bookings-" + new Date() + ".csv";

      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
      }

      link.setAttribute("href", encodeURI(csv));
      link.setAttribute("download", filename);
      link.click();
    }

    const Export = ({ onExport }) => (
      <button
        className="actionButton"
        onClick={(e) => onExport(e.target.value)}
      >
        Export
      </button>
    );

    const TextField = styled.input`
      height: 32px;
      width: 200px;
      border-radius: 3px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid #e5e5e5;
      padding: 0 32px 0 16px;

      &:hover {
        cursor: pointer;
      }
    `;

    const ClearButton = styled.button`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      height: 34px;
      width: 32px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    `;

    const FilterComponent = ({ filterText, onFilter, onClear }) => (
      <>
        <TextField
          id="search"
          type="text"
          placeholder="Filter"
          value={filterText}
          onChange={onFilter}
        />
        <ClearButton onClick={onClear}>X</ClearButton>
      </>
    );
    const columns = [
      {
        name: "Booked On",
        selector: "date",
        sortable: true,
        cell: (row) => <Moment>{row.date}</Moment>,
      },
      {
        name: "Slot",
        selector: "bookingslot",
        sortable: true,
        cell: (row) => <Moment>{row.bookingslot}</Moment>,
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
      },
      {
        name: "Charged",
        selector: "charged",
        sortable: true,
        cell: (row) => <span>{row.charged.toFixed(2)}</span>,
      },
      {
        name: "Charged in",
        selector: "currency",
        sortable: true,
      },
      {
        name: "Customer",
        selector: "user",
        sortable: true,
        cell: (row) => (
          <div>
            {row.user.email}({`${row.user.fname} ${row.user.lname}`})<br />
            Phone Number: {row.user.phone}
            <br />
            Address: {row.user.address1}
            <br />
            {row.user.address2 ? row.user.address2 : ""}
            {row.user.address2 && <br />}
            City: {row.user.city}
            <br />
            State: {row.user.state}
            <br />
            Country: {row.user.country}
            <br />
            Postal Code: {row.user.postalcode}
            <br />
          </div>
        ),
        grow: 2,
      },
      {
        name: "Coach",
        selector: "coach",
        sortable: true,
        cell: (row) => (
          <div>
            {row.coach.email}({`${row.coach.fname} ${row.coach.lname}`})
          </div>
        ),
        grow: 2,
      },
      {
        name: "Actions",
        cell: (row) => (
          <>
            <Link to={`/booking/${row._id}`}>Manage Lesson</Link>
            <span
              style={{
                color: "var(--accent-color)",
                cursor: "pointer",
                marginLeft: "5px",
              }}
              onClick={() => {
                this.deleteit(row._id);
                return false;
              }}
            >
              Delete Lesson
            </span>
          </>
        ),
        grow: 2,
      },
    ];
    const BasicTable = () => {
      const [filterText, setFilterText] = React.useState("");
      const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
      );
      const filteredItems = this.state.lessons.filter(
        (item) =>
          (item.user.fname && item.user.fname.includes(filterText)) ||
          (item.user.lname && item.user.lname.includes(filterText)) ||
          (item.user.email && item.user.email.includes(filterText)) ||
          (item.coach.fname && item.coach.fname.includes(filterText)) ||
          (item.coach.lname && item.coach.lname.includes(filterText)) ||
          (item.coach.email && item.coach.email.includes(filterText)) ||
          (item.status && item.status.includes(filterText)) ||
          (item.currency && item.currency.includes(filterText))
      );

      const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
          if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
          }
        };

        return (
          <>
            <Export onExport={() => downloadCSV(lessons)} />
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              onClear={handleClear}
              filterText={filterText}
            />
          </>
        );
      }, [filterText, resetPaginationToggle]);

      return (
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          theme="dark"
        />
      );
    };
    return (
      <React.Fragment>
        <Container className="bookingsList">
          <Row>
            <Col>
              <div className="pageTitle">
                <h2>Bookings / Lessons</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <BasicTable />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.data,
});

LessonsList.propTypes = {
  auth: PropTypes.object.isRequired,
  getLessons: PropTypes.func.isRequired,
  deletedata: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { getLessons, deletedata, setInfo })(
  withRouter(LessonsList)
);
