import { SET_DATA } from "../types";

const initialState = {
    orders: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA:
            return {
                ...state,
                [action.payload.for]: action.payload.data
            };
        default:
            return state;
    }
};
