/* global i18n */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import Moment from "react-moment";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { setInfo } from "../../inc/actions/infoActions";
// import S3 from "react-aws-s3";

import {
  getLessons,
  changeLessonStatus,
  changeLessonlink,
} from "../../inc/actions/adminActions";

class LessonDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is404: false,
      lesson: {},
      auth: {},
      inputToggled: false,
      linkToggled: false,
      uploadToggled: false,
      isUploading: false,
    };
    this.props.getLessons();
    this.props.setInfo({
      for: "currentPage",
      data: "bookings",
    });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.id) {
        if (nextProps.data) {
          if (nextProps.data.lessons) {
            let found = false;
            for (
              let index = 0;
              index < nextProps.data.lessons.length;
              index++
            ) {
              let lesson = nextProps.data.lessons[index];
              if (lesson._id === nextProps.id) {
                update.id = nextProps.id;
                update.is404 = false;
                found = true;
                update.lesson = lesson;
              }
            }
            if (!found) {
              update.is404 = true;
              update.id = nextProps.id;
            }
          }
        }
      } else {
        console.log("redirecting to 404", 4);
        nextProps.history.push("/404");
      }
      if (nextProps.auth) {
        update.auth = nextProps.auth;
      }
      if (!nextProps.auth.isAuthenticated) {
        nextProps.history.push(`/login/?returnto=/lesson/${nextProps.id}`);
      }
      return update;
    } else {
      return null;
    }
  }
  changeStatus = (val) => {
    this.props.changeLessonStatus(this.state.lesson._id, val);
    this.props.getLessons();
    this.setState({
      ...this.state,
      inputToggled: false,
    });
  };
  showChange = () => {
    this.setState({
      ...this.state,
      inputToggled: true,
    });
  };
  showLink = () => {
    this.setState({
      ...this.state,
      linkToggled: true,
    });
  };
  updateLink = () => {
    let val = document.querySelector("#meetingInput").value;
    if (!val) {
      toast.error("Please enter link");
      return false;
    }
    this.props.changeLessonlink(this.state.lesson._id, val);
    this.setState({
      ...this.state,
      linkToggled: false,
      lesson: {
        ...this.state.lesson,
        meetinglink: val,
      },
    });
    this.props.getLessons();
  };

  // uploadFile = (e) => {
  //   let { files } = e.target;
  //   const s3config = {
  //     bucketName: "equireel-public",
  //     dirName: `lessons/${this.state.lesson._id}` /* optional */,
  //     region: "eu-west-1",
  //     accessKeyId: "AKIAJPM53KOXH2ISAHLQ",
  //     secretAccessKey: "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",
  //   };
  //   const ReactS3Client = new S3(s3config);
  //   this.setState({
  //     ...this.state,
  //     isUploading: true,
  //   });
  //   for (let index = 0; index < files.length; index++) {
  //     const file = files[index];
  //     if (file) {
  //       let fileName = file.name.replace(/\.[^/.]+$/, "");
  //       ReactS3Client.uploadFile(file, fileName)
  //         .then((data) => {
  //           this.props.addAttachmentFile(
  //             { lesson: this.state.lesson._id, file: data.location },
  //             "coach"
  //           );
  //           if (Number(index) === Number(files.length)) {
  //             this.setState({
  //               ...this.state,
  //               isUploading: false,
  //               uploadToggled: false,
  //             });
  //             this.forceUpdate();
  //           }
  //         })
  //         .catch((err) => {
  //           console.error(err);
  //           toast.error(i18n("ue"));
  //           if (Number(index) === Number(files.length)) {
  //             this.setState({
  //               ...this.state,
  //               isUploading: false,
  //               uploadToggled: false,
  //             });
  //             this.forceUpdate();
  //           }
  //         });
  //     }
  //   }
  //   e.target.value = null;
  // };

  render() {
    const { lesson } = this.state;
    const fileColumns = [
      {
        name: "Name",
        selector: "key",
        sortable: true,
        cell: (row) => (
          <a href={row} target="_blank">
            {row.split(`/`).pop()}
          </a>
        ),
      },
    ];
    return (
      <>
        {lesson && (
          <React.Fragment>
            {lesson && lesson.coach && (
              <Container className="lessonWrap">
                <Row>
                  <Col sm={12}>
                    <div className="backToLesson">
                      <Link to="/bookings">back to all bookings</Link>
                    </div>
                  </Col>
                  <Col sm={12} md={8}>
                    <p>
                      <a href={`mailto:${lesson.user.email}`}>Email Customer</a>
                      &nbsp;
                      <a href={`mailto:${lesson.coach.email}`}>Email Coach</a>
                    </p>
                    <div className="fileSection">
                      <h2>Files</h2>
                      <DataTable
                        columns={fileColumns}
                        data={lesson.files}
                        theme="dark"
                      />
                    </div>
                  </Col>
                  <Col sm={12} md={4}>
                    <div className="card alignedTable">
                      <div className="cardTitle">Lesson Details</div>
                      <div className="cardContent infoList withPadding bg-secondary">
                        <ul className="contentList">
                          <li>
                            <div className="label">Charged</div>
                            <div className="value">
                              <div className="price">
                                <span className="value">
                                  {lesson.charged.toFixed(2)}
                                </span>
                                <span className="currency">
                                  {lesson.currency}
                                </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="label">Slot Date</div>
                            <div className={`value`}>
                              <Moment format="DD/MM/YYYY">
                                {lesson.bookingslot}
                              </Moment>
                            </div>
                          </li>
                          <li>
                            <div className="label">Slot Time</div>
                            <div className={`value`}>
                              <Moment format="hh:mm A">
                                {lesson.bookingslot}
                              </Moment>
                            </div>
                          </li>
                          <li>
                            <div className="label">Booked On</div>
                            <div className={`value`}>
                              <Moment format="DD/MM/YYYY">{lesson.date}</Moment>
                            </div>
                          </li>
                          <li>
                            <div className="label">Status</div>
                            {!this.state.inputToggled && (
                              <div
                                className={`value ${
                                  lesson.status === "In Progress"
                                    ? "text-info"
                                    : lesson.status === "Completed"
                                    ? "text-success"
                                    : lesson.status === "Awaiting Upload"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {lesson.status}
                                <span
                                  className="color-accent"
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "8px",
                                  }}
                                  onClick={this.showChange}
                                >
                                  Change
                                </span>
                              </div>
                            )}
                            {this.state.inputToggled && (
                              <div className={`value`}>
                                <select
                                  onChange={(e) =>
                                    this.changeStatus(e.target.value)
                                  }
                                  className="form-control"
                                  defaultValue={lesson.status}
                                >
                                  <option value="In Progress">
                                    In Progress
                                  </option>
                                  <option value="Awaiting Upload">
                                    Awaiting Upload
                                  </option>
                                  <option value="Completed">Completed</option>
                                  <option value="Cancelled">Cancelled</option>
                                </select>
                              </div>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            )}
          </React.Fragment>
        )}
        {!lesson && (
          <Container className="fullScreenCenter vh85">
            <h1>loading...</h1>
            <div className="spacer"></div>
          </Container>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.data,
});

LessonDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  getLessons: PropTypes.func.isRequired,
  changeLessonStatus: PropTypes.func.isRequired,
  changeLessonlink: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  getLessons,
  changeLessonStatus,
  changeLessonlink,
  setInfo,
})(withRouter(LessonDetails));
