import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import DataTable from "react-data-table-component";
import Moment from "react-moment";
import styled from "styled-components";

import { getOrders, deletedata } from "../../inc/actions/adminActions";
import { setInfo } from "../../inc/actions/infoActions";

class OrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      auth: "",
    };
    this.props.getOrders();
    this.props.setInfo({
      for: "currentPage",
      data: "orders",
    });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (!nextProps.auth.isAuthenticated) {
      nextProps.history.push("/");
    }
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.data) {
        if (nextProps.data.orders) {
          update.orders = nextProps.data.orders;
        }
      }
      return update;
    } else {
      return null;
    }
  }
  errorAlert = (e) => {
    if (this.state.error) {
      if (typeof this.state.error[e] !== "undefined") {
        if (this.state.error[e] !== "") {
          return <p className="text-danger">{this.state.error[e]}</p>;
        }
      }
    }
  };
  deleteit = (id) => {
    let confirmed = window.confirm(
      "Are you sure you want to delete this order?"
    );
    if (confirmed) {
      this.props.deletedata("orders", id);
    }
    return false;
  };

  render() {
    const { orders } = this.state;
    function convertArrayOfObjectsToCSV(array) {
      let result;

      const columnDelimiter = ",";
      const lineDelimiter = "\n";

      const keys = [
        "Order Date",
        "Customer Full Name",
        "Customer Email",
        "Event Name",
        "Horse Name",
        "",
        "Share on Facebook Yes/No",
        "Include Faults",
        "Video Format (Video/DVD)",
        "",
        "Video Format (Video/DVD)",
        "",
        "Order Notes",
        "",
        "Phone (Billing)",
        "Company (Billing)",
        "Address 1 (Billing)",
        "Address 2 (Billing)",
        "City (Billing)",
        "Country Name (Billing)",
        "State Name (Billing)",
        "Zip (Billing)",
        "Address 1&2 (Billing)",
        "Address 1&2 (Shipping)",
      ];

      result = "";
      result += keys.join(columnDelimiter);
      result += lineDelimiter;

      array.forEach((order) => {
        order.cart.forEach((item) => {
          let ctr = 0;
          keys.forEach((key) => {
            if (ctr > 0) result += columnDelimiter;

            let value = "";
            switch (key) {
              case "":
                value = "";
                break;

              case "Order Date":
                value = order.date;
                break;

              case "Customer Full Name":
                value = order.user.fname + " " + order.user.lname;
                break;

              case "Customer Email":
                value = order.user.email;
                break;

              case "Event Name":
                value = item.Event;
                break;

              case "Horse Name":
                value = item.Info;
                break;

              case "Share on Facebook Yes/No":
                value = order.shareconsent ? "Yes" : "No";
                break;

              case "Include Faults":
                value = order.includefaults;
                break;

              case "Video Format (Video/DVD)":
                value = item.Product;
                break;

              case "Order Notes":
                value = item.Info;
                break;

              case "Phone (Billing)":
                value = order.user.phone;
                break;

              case "Company (Billing)":
                value = "";
                break;

              case "Address 1 (Billing)":
                value = order.user.address1;
                break;

              case "Address 2 (Billing)":
                value = order.user.address2;
                break;

              case "City (Billing)":
                value = order.user.city;
                break;

              case "Country Name (Billing)":
                value = order.user.country;
                break;

              case "State Name (Billing)":
                value = order.user.state;
                break;

              case "Zip (Billing)":
                value = order.user.postalcode;
                break;

              case "Address 1&2 (Billing)":
                value = order.user.address1 + " " + order.user.address2;
                break;

              case "Address 1&2 (Shipping)":
                value = order.user.address1 + " " + order.user.address2;
                break;

              default:
                break;
            }

            value = value.replace(/{/g, "").replace(/#/g, "no.");
            result += '"' + value + '"';
            ctr++;
          });
          result += lineDelimiter;
        });
      });

      return result;
    }
    function downloadCSV(array) {
      const link = document.createElement("a");
      let csv = convertArrayOfObjectsToCSV(array);
      if (csv == null) return;

      const filename = "orders-" + new Date() + ".csv";

      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
      }

      link.setAttribute("href", encodeURI(csv));
      link.setAttribute("download", filename);
      link.click();
    }

    const Export = ({ onExport }) => (
      <button
        className="actionButton"
        onClick={(e) => onExport(e.target.value)}
      >
        Export
      </button>
    );

    const TextField = styled.input`
      height: 32px;
      width: 200px;
      border-radius: 3px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid #e5e5e5;
      padding: 0 32px 0 16px;

      &:hover {
        cursor: pointer;
      }
    `;

    const ClearButton = styled.button`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      height: 34px;
      width: 32px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    `;

    const FilterComponent = ({ filterText, onFilter, onClear }) => (
      <>
        <TextField
          id="search"
          type="text"
          placeholder="Filter"
          value={filterText}
          onChange={onFilter}
        />
        <ClearButton onClick={onClear}>X</ClearButton>
      </>
    );
    const columns = [
      {
        name: "Date",
        selector: "date",
        sortable: true,
        cell: (row) => <Moment>{row.date}</Moment>,
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
      },
      {
        name: "Charged",
        selector: "charged",
        sortable: true,
        cell: (row) => <span>{row.charged.toFixed(2)}</span>,
      },
      {
        name: "Charged in",
        selector: "currency",
        sortable: true,
      },
      {
        name: "Customer",
        selector: "user",
        sortable: true,
        cell: (row) => (
          <div>
            {row.user.email}({`${row.user.fname} ${row.user.lname}`})<br />
            Phone Number: {row.user.phone}
            <br />
            Address: {row.user.address1}
            <br />
            {row.user.address2 ? row.user.address2 : ""}
            {row.user.address2 && <br />}
            City: {row.user.city}
            <br />
            State: {row.user.state}
            <br />
            Country: {row.user.country}
            <br />
            Postal Code: {row.user.postalcode}
            <br />
          </div>
        ),
        grow: 2,
      },
      {
        name: "Actions",
        cell: (row) => (
          <>
            <Link to={`/order/${row._id}`}>Manage Order</Link>
            <span
              style={{
                color: "var(--accent-color)",
                cursor: "pointer",
                marginLeft: "5px",
              }}
              onClick={() => {
                this.deleteit(row._id);
                return false;
              }}
            >
              Delete Order
            </span>
          </>
        ),
        grow: 2,
      },
    ];
    const BasicTable = () => {
      const [filterText, setFilterText] = React.useState("");
      const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
      );
      const filteredItems = this.state.orders.filter(
        (item) =>
          (item.user.name && item.user.name.includes(filterText)) ||
          (item.user.email && item.user.email.includes(filterText)) ||
          (item.status && item.status.includes(filterText)) ||
          (item.currency && item.currency.includes(filterText))
      );

      const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
          if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
          }
        };

        return (
          <>
            <Export onExport={() => downloadCSV(orders)} />
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              onClear={handleClear}
              filterText={filterText}
            />
          </>
        );
      }, [filterText, resetPaginationToggle]);

      return (
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          theme="dark"
        />
      );
    };
    return (
      <React.Fragment>
        <Container className="ordersList">
          <Row>
            <Col>
              <div className="pageTitle">
                <h2>Orders</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <BasicTable />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.data,
});

OrderList.propTypes = {
  auth: PropTypes.object.isRequired,
  getOrders: PropTypes.func.isRequired,
  deletedata: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { getOrders, deletedata, setInfo })(
  withRouter(OrderList)
);
