import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import DataTable from "react-data-table-component";
import Moment from "react-moment";
import styled from "styled-components";
import { setInfo } from "../../inc/actions/infoActions";

import { getCoaches, deletedata } from "../../inc/actions/adminActions";

class CoachList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coaches: [],
      auth: "",
    };
    this.props.getCoaches();
    this.props.setInfo({
      for: "currentPage",
      data: "coaches",
    });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (!nextProps.auth.isAuthenticated) {
      nextProps.history.push("/");
    }
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.data) {
        if (nextProps.data.coaches) {
          update.coaches = nextProps.data.coaches;
        }
      }
      return update;
    } else {
      return null;
    }
  }
  errorAlert = (e) => {
    if (this.state.error) {
      if (typeof this.state.error[e] !== "undefined") {
        if (this.state.error[e] !== "") {
          return <p className="text-danger">{this.state.error[e]}</p>;
        }
      }
    }
  };

  deleteit = (id) => {
    let confirmed = window.confirm(
      "Are you sure you want to delete this coach?"
    );
    if (confirmed) {
      this.props.deletedata("coaches", id);
    }
    return false;
  };

  render() {
    const TextField = styled.input`
      height: 32px;
      width: 200px;
      border-radius: 3px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid #e5e5e5;
      padding: 0 32px 0 16px;

      &:hover {
        cursor: pointer;
      }
    `;

    const ClearButton = styled.button`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      height: 34px;
      width: 32px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    `;

    const FilterComponent = ({ filterText, onFilter, onClear }) => (
      <>
        <TextField
          id="search"
          type="text"
          placeholder="Filter"
          value={filterText}
          onChange={onFilter}
        />
        <ClearButton onClick={onClear}>X</ClearButton>
      </>
    );
    const columns = [
      {
        name: "ID",
        selector: "_id",
        sortable: true,
      },
      {
        name: "Information",
        sortable: true,
        cell: (row) => (
          <div>
            {row.email}({`${row.fname} ${row.lname}`})<br />
            Fee: {row.fee}
            <br />
          </div>
        ),
        grow: 3,
      },
      {
        name: "Registered On",
        selector: "date",
        sortable: true,
        cell: (row) => <Moment>{row.date}</Moment>,
      },
      {
        name: "Actions",
        cell: (row) => (
          <span
            style={{
              color: "var(--accent-color)",
              cursor: "pointer",
              marginLeft: "5px",
            }}
            onClick={() => {
              this.deleteit(row._id);
              return false;
            }}
          >
            Delete Coach
          </span>
        ),
      },
    ];
    const BasicTable = () => {
      const [filterText, setFilterText] = React.useState("");
      const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
      );
      const filteredItems = this.state.coaches.filter(
        (item) =>
          (item.name && item.name.includes(filterText)) ||
          (item.email && item.email.includes(filterText))
      );

      const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
          if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
          }
        };

        return (
          <FilterComponent
            onFilter={(e) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
          />
        );
      }, [filterText, resetPaginationToggle]);

      return (
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          theme="dark"
        />
      );
    };
    return (
      <React.Fragment>
        <Container className="usersList">
          <Row>
            <Col>
              <div className="pageTitle">
                <h2>Coaches</h2>
                <div className="cta">
                  <Link
                    to="/coaches/create/"
                    className="button bg-secondary color-text"
                  >
                    Create Coach
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <BasicTable />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.data,
});

CoachList.propTypes = {
  auth: PropTypes.object.isRequired,
  getCoaches: PropTypes.func.isRequired,
  deletedata: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { getCoaches, deletedata, setInfo })(
  withRouter(CoachList)
);
