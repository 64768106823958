import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { unsetUser } from "../../inc/actions/authActions";
import Logo from "./../../assets/Logo.png";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      return {
        ...prevProps,
      };
    }
  }
  logoutUser = (e) => {
    e.preventDefault();
    this.props.unsetUser("/");
    return false;
  };
  render() {
    const { isAuthenticated } = this.props.auth;
    const currentPage = this.props.info.currentPage;
    const guestLinks = (
      <React.Fragment>
        <Link to="/" className={"nav-link bg-accent"}>
          Login
        </Link>
      </React.Fragment>
    );
    const userLinks = (
      <React.Fragment>
        <Link
          to="/orders"
          className={
            currentPage === "orders" ? "nav-link bg-accent" : "nav-link"
          }
        >
          Orders
        </Link>
        <Link
          to="/bookings"
          className={
            currentPage === "bookings" ? "nav-link bg-accent" : "nav-link"
          }
        >
          Bookings
        </Link>
        <Link
          to="/customers"
          className={
            currentPage === "customers" ? "nav-link bg-accent" : "nav-link"
          }
        >
          Customers
        </Link>
        <Link
          to="/coaches"
          className={
            currentPage === "coaches" ? "nav-link bg-accent" : "nav-link"
          }
        >
          Coaches
        </Link>
        <Link
          to="/promos"
          className={
            currentPage === "promos" ? "nav-link bg-accent" : "nav-link"
          }
        >
          Promos
        </Link>
        <Link
          to="/admins"
          className={
            currentPage === "admins" ? "nav-link bg-accent" : "nav-link"
          }
        >
          Admins
        </Link>
        <Link
          to="/tickets"
          className={
            currentPage === "tickets" ? "nav-link bg-accent" : "nav-link"
          }
        >
          Support Tickets
        </Link>
        <Link
          to="/import"
          className={
            currentPage === "import" ? "nav-link bg-accent" : "nav-link"
          }
        >
          Import
        </Link>
        <Link
          className="nav-link"
          onClick={this.logoutUser.bind(this)}
          style={{ cursor: "pointer" }}
          to="/logout"
        >
          Logout
        </Link>
      </React.Fragment>
    );
    return (
      <header>
        <Container fluid className="navbarSection">
          <Navbar collapseOnSelect expand="lg">
            <Navbar.Brand>
              <Link to="/">
                <img src={Logo} alt="Equireel" className="img-fluid" />
              </Link>
            </Navbar.Brand>
            <Nav className="ml-auto no_user--sessionActions">
              {isAuthenticated ? userLinks : guestLinks}
            </Nav>
          </Navbar>
        </Container>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  info: state.info,
});

Header.propTypes = {
  unsetUser: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { unsetUser })(Header);
