import { SET_ERROR, UNSET_ERROR } from "../types";

const initialState = {
  registerationError: "",
  loginError: "",
  createPostError: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        [action.payload.for]: action.payload.msg
      };
    case UNSET_ERROR:
      return {
        ...state,
        [action.payload]: ""
      };
    default:
      return state;
  }
};
