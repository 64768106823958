import { SET_INFO } from "../types";

const initialState = {
  currentPage: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INFO:
      return {
        ...state,
        [action.payload.for]: action.payload.data,
      };
    default:
      return state;
  }
};
