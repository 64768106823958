import { SET_ERROR, SET_DATA } from "../types";
import axios from "axios";
import { toast } from "react-toastify";
import io from "socket.io-client";
import store from "./../../inc/store";
import Emitter from "./../../inc/emitter";

export const receiveMessage = (msg) => (dispatch) => {
  console.log("message received", msg);
  Emitter.emit("CHAT_MSG", msg);
};

let socket;

if (!socket) {
  // socket = io(":8080");
  socket = io("https://equireel.co.uk:8085");
  socket.on("msg", (msg) => {
    store.dispatch(receiveMessage(msg));
  });
}

export const sendMessage = (ticket, message) => (dispatch) => {
  try {
    console.log("sending message", ticket, message);
    socket.emit("msg", { ticket, message });
  } catch (err) {
    console.error(err);
  }
};

export const importCSV = (formData, history, returnto = "/") => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "ie/i/", formData)
    .then(function (res) {
      toast.success(res.data);
      history.push(returnto);
    })
    .catch(function (err) {
      console.log(err);
      dispatch({
        type: SET_ERROR,
        payload: {
          for: "importCSVError",
          msg: "unexpected error",
        },
      });
    });
};

export const getOrders = () => (dispatch) => {
  axios
    .get(process.env.REACT_APP_API_PATH + "orders")
    .then((res) => {
      dispatch({
        type: SET_DATA,
        payload: {
          for: "orders",
          data: res.data,
        },
      });
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = "undefined error";
      }
      toast.error(e);
    });
};

export const changeOrderStatus = (order, status) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "orders/changestatus", {
      order,
      status,
    })
    .then((res) => {
      toast.success("Order Status Updated");
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = "undefined error";
      }
      toast.error(e);
    });
};

export const createPromo = (promo, history) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "promos/create", promo)
    .then((res) => {
      toast.success(`Promo ${promo.code} Created`);
      history.push("/promos");
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = "undefined error";
      }
      toast.error(e);
    });
};

export const getPromos = () => (dispatch) => {
  axios
    .get(process.env.REACT_APP_API_PATH + "promos")
    .then((res) => {
      dispatch({
        type: SET_DATA,
        payload: {
          for: "promos",
          data: res.data,
        },
      });
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = "undefined error";
      }
      toast.error(e);
    });
};

export const getUsers = () => (dispatch) => {
  axios
    .get(process.env.REACT_APP_API_PATH + "users")
    .then((res) => {
      dispatch({
        type: SET_DATA,
        payload: {
          for: "users",
          data: res.data,
        },
      });
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = "undefined error";
      }
      toast.error(e);
    });
};
export const getCoaches = () => (dispatch) => {
  axios
    .get(process.env.REACT_APP_API_PATH + "coaches")
    .then((res) => {
      dispatch({
        type: SET_DATA,
        payload: {
          for: "coaches",
          data: res.data,
        },
      });
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = "undefined error";
      }
      toast.error(e);
    });
};
export const getLessons = () => (dispatch) => {
  axios
    .get(process.env.REACT_APP_API_PATH + "lessons")
    .then((res) => {
      dispatch({
        type: SET_DATA,
        payload: {
          for: "lessons",
          data: res.data,
        },
      });
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = "undefined error";
      }
      toast.error(e);
    });
};

export const getTickets = () => (dispatch) => {
  axios
    .get(process.env.REACT_APP_API_PATH + "tickets")
    .then((res) => {
      dispatch({
        type: SET_DATA,
        payload: {
          for: "tickets",
          data: res.data,
        },
      });
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = "undefined error";
      }
      toast.error(e);
    });
};

export const changeTicketStatus = (ticket, status) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "tickets/changestatus", {
      ticket,
      status,
    })
    .then((res) => {
      toast.success("Ticket Status Updated");
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = "undefined error";
      }
      toast.error(e);
    });
};

export const deletedata = (type, id) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + `${type}/delete`, { id })
    .then((res) => {
      switch (type) {
        case "users":
          dispatch(getUsers());
          break;

        case "coaches":
          dispatch(getCoaches());
          break;

        case "orders":
          dispatch(getOrders());
          break;

        case "promos":
          dispatch(getPromos());
          break;
        case "admins":
          dispatch(getAdminUsers());
          break;
        case "lessons":
          dispatch(getLessons());
          break;

        default:
          break;
      }
      toast.success("Deleted");
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = "undefined error";
      }
      toast.error(e);
    });
};

export const getAdminUsers = () => (dispatch) => {
  axios
    .get(process.env.REACT_APP_API_PATH + "admins")
    .then((res) => {
      dispatch({
        type: SET_DATA,
        payload: {
          for: "adminusers",
          data: res.data,
        },
      });
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = "undefined error";
      }
      toast.error(e);
    });
};

export const createAdmin = (userData, history, returnto = "/admins") => (
  dispatch
) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "admins/create", userData)
    .then((res) => {
      toast.success("Admin Created");
      history.push(returnto);
    })
    .catch((err) => {
      let e =
        typeof err.response !== "undefined"
          ? err.response.data.error
          : "undefined error";
      if (!e) {
        e = "undefined error";
      }
      toast.error(e);
    });
};
export const createCoach = (userData, history, returnto = "/coaches") => (
  dispatch
) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "coaches/create", userData, {})
    .then((res) => {
      toast.success("Coach Created");
      history.push(returnto);
    })
    .catch((err) => {
      let e =
        typeof err.response !== "undefined"
          ? err.response.data.error
          : "undefined error";
      if (!e) {
        e = "undefined error";
      }
      toast.error(e);
    });
};

export const changeLessonStatus = (lesson, status) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "lessons/changestatus", {
      lesson,
      status,
    })
    .then((res) => {
      toast.success("Lesson status updated");
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = "undefined error";
      }
      toast.error(e);
    });
};
export const changeLessonlink = (lesson, link) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "lessons/changelink", {
      lesson,
      link,
    })
    .then((res) => {
      toast.success("Lesson link updated");
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = "undefined error";
      }
      toast.error(e);
    });
};
