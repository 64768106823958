import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import DataTable from "react-data-table-component";
import Moment from "react-moment";
import styled from "styled-components";

import { getUsers, deletedata } from "../../inc/actions/adminActions";
import { setInfo } from "../../inc/actions/infoActions";

class UsersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      auth: "",
    };
    this.props.getUsers();
    this.props.setInfo({
      for: "currentPage",
      data: "customers",
    });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (!nextProps.auth.isAuthenticated) {
      nextProps.history.push("/");
    }
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.data) {
        if (nextProps.data.users) {
          update.users = nextProps.data.users;
        }
      }
      return update;
    } else {
      return null;
    }
  }
  errorAlert = (e) => {
    if (this.state.error) {
      if (typeof this.state.error[e] !== "undefined") {
        if (this.state.error[e] !== "") {
          return <p className="text-danger">{this.state.error[e]}</p>;
        }
      }
    }
  };

  deleteit = (id) => {
    let confirmed = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (confirmed) {
      this.props.deletedata("users", id);
    }
    return false;
  };

  render() {
    const TextField = styled.input`
      height: 32px;
      width: 200px;
      border-radius: 3px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid #e5e5e5;
      padding: 0 32px 0 16px;

      &:hover {
        cursor: pointer;
      }
    `;

    const ClearButton = styled.button`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      height: 34px;
      width: 32px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    `;

    const FilterComponent = ({ filterText, onFilter, onClear }) => (
      <>
        <TextField
          id="search"
          type="text"
          placeholder="Filter"
          value={filterText}
          onChange={onFilter}
        />
        <ClearButton onClick={onClear}>X</ClearButton>
      </>
    );
    const columns = [
      {
        name: "ID",
        selector: "_id",
        sortable: true,
      },
      {
        name: "Information",
        sortable: true,
        cell: (row) => (
          <div>
            {row.email}({`${row.fname} ${row.lname}`})<br />
            Phone Number: {row.phone}
            <br />
            Address: {row.address1}
            <br />
            {row.address2 ? row.address2 : ""}
            {row.address2 && <br />}
            City: {row.city}
            <br />
            State: {row.state}
            <br />
            Country: {row.country}
            <br />
            Postal Code: {row.postalcode}
            <br />
          </div>
        ),
        grow: 3,
      },
      {
        name: "Registered On",
        selector: "date",
        sortable: true,
        cell: (row) => <Moment>{row.expiryOn}</Moment>,
      },
      {
        name: "Actions",
        cell: (row) => (
          <span
            style={{
              color: "var(--accent-color)",
              cursor: "pointer",
              marginLeft: "5px",
            }}
            onClick={() => {
              this.deleteit(row._id);
              return false;
            }}
          >
            Delete Customer
          </span>
        ),
      },
    ];
    const BasicTable = () => {
      const [filterText, setFilterText] = React.useState("");
      const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
      );
      const filteredItems = this.state.users.filter(
        (item) =>
          (item.name && item.name.includes(filterText)) ||
          (item.email && item.email.includes(filterText))
      );

      const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
          if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
          }
        };

        return (
          <FilterComponent
            onFilter={(e) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
          />
        );
      }, [filterText, resetPaginationToggle]);

      return (
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          theme="dark"
        />
      );
    };
    return (
      <React.Fragment>
        <Container className="usersList">
          <Row>
            <Col>
              <div className="pageTitle">
                <h2>Customers</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <BasicTable />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.data,
});

UsersList.propTypes = {
  auth: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  deletedata: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { getUsers, deletedata, setInfo })(
  withRouter(UsersList)
);
