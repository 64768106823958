import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { setInfo } from "../../inc/actions/infoActions";
import { createPromo } from "../../inc/actions/adminActions";

class CreatePromo extends React.Component {
  constructor(props) {
    super(props);
    let expiryOn = new Date();
    expiryOn.setFullYear(expiryOn.getFullYear() + 5);
    this.state = {
      type: "percentage",
      code: "",
      amount: "",
      expiryOn,
      usageleft: -1,
    };
    this.props.setInfo({
      for: "currentPage",
      data: "promos",
    });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.auth) {
        if (!nextProps.auth.isAuthenticated) {
          nextProps.history.push(`/`);
        } else {
          update.auth = nextProps.auth;
        }
      } else {
        nextProps.history.push(`/`);
      }
      return update;
    } else {
      return null;
    }
  }
  handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "amount") {
      if (this.state.type === "percentage") {
        if (value > 100) {
          value = 100;
          e.target.value = 100;
          toast.info("max percentage set to 100. Product will be free.");
        }
      }
    }
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleDateChange = (date) => {
    this.setState({
      expiryOn: date,
    });
  };

  createPromo = (e) => {
    e.preventDefault();
    let { type, amount, code, expiryOn, usageleft } = this.state;
    let promo = {
      type,
      amount,
      code,
      expiryOn,
      usageleft,
    };
    this.props.createPromo(promo, this.props.history);
  };

  render() {
    return (
      <Container className="createPromoWrap">
        <Row>
          <Col>
            <div className="pageTitle">
              <h2>Create Promo</h2>
              <div className="cta">
                <Link to="/promos" className="button bg-secondary color-text">
                  Back to all Promos
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="card">
              <div className="cardContent withPadding">
                <form onSubmit={(e) => this.createPromo(e)}>
                  <label htmlFor="type">Type</label>
                  <select
                    name="type"
                    onChange={this.handleChange}
                    className="form-control"
                    id="type"
                  >
                    <option value="percentage">Percentage</option>
                    <option value="fixed">Fixed Amount</option>
                  </select>
                  <label htmlFor="amount">Amount</label>
                  <input
                    max={this.state.type === "percentage" ? "100" : ""}
                    id="amount"
                    className="form-control"
                    type="number"
                    name="amount"
                    placeholder="Amount"
                    onBlur={this.handleChange}
                  />
                  <label htmlFor="code">Code</label>
                  <input
                    id="code"
                    className="form-control"
                    type="text"
                    name="code"
                    placeholder="Promo Code"
                    onBlur={this.handleChange}
                  />
                  <Row>
                    <Col>
                      <label htmlFor="expiryOn">Expire On</label>
                      <div>
                        <DatePicker
                          selected={this.state.expiryOn}
                          onChange={this.handleDateChange}
                          className="form-control"
                          placeholder="Select Expiry Date"
                        />
                      </div>
                    </Col>
                    <Col>
                      <label htmlFor="usageleft">Usage Limit</label>
                      <div>
                        <input
                          id="usageleft"
                          className="form-control"
                          type="number"
                          name="usageleft"
                          placeholder="Usage Limit"
                          min="-1"
                          defaultValue="-1"
                          onBlur={this.handleChange}
                        />
                        <small>Input -1 for unlimited usage</small>
                      </div>
                    </Col>
                  </Row>
                  <div style={{ textAlign: "right" }}>
                    <input
                      type="submit"
                      className="button bg-accent color-text"
                      value="Create Promo"
                    />
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

CreatePromo.propTypes = {
  auth: PropTypes.object.isRequired,
  createPromo: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { createPromo, setInfo })(
  withRouter(CreatePromo)
);
