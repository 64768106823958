import React from "react";
import { Container, Row, Col } from "react-bootstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <Container>
          <Row>
            <Col>
              <p className="color-text">
                &copy; 2020 - All Rights Reserved
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
