import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { toast } from "react-toastify";

import { createAdmin } from "../../inc/actions/adminActions";
import { checkMinLength, checkEmail } from "../../inc/Validation";
import { setInfo } from "../../inc/actions/infoActions";

class CreateAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      name: "",
    };
    this.props.setInfo({
      for: "currentPage",
      data: "admins",
    });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.auth) {
        if (!nextProps.auth.isAuthenticated) {
          nextProps.history.push(`/`);
        } else {
          update.auth = nextProps.auth;
        }
      } else {
        nextProps.history.push(`/`);
      }
      return update;
    } else {
      return null;
    }
  }
  changeHandler = (e) => {
    let { name, value } = e.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  validate = () => {
    var errorMsg = "";
    const { email, password, name } = this.state;
    if (!name || !checkMinLength(name, 4)) {
      errorMsg = "Name should be atleast 4 characters long";
      toast.error(errorMsg);
      return false;
    }
    if (!email || !checkEmail(email)) {
      errorMsg = "Invalid email";
      toast.error(errorMsg);
      return false;
    }
    if (!password || !checkMinLength(password, 6)) {
      errorMsg = "Password should be atleast 6 characters long";
      toast.error(errorMsg);
      return false;
    }
    return true;
  };

  submitHandler = (e) => {
    e.preventDefault();
    const { email, password, name } = this.state;
    if (this.validate()) {
      const newUser = {
        email,
        name,
        password,
      };

      this.props.createAdmin(newUser, this.props.history);
    } else {
      return false;
    }
  };

  render() {
    return (
      <Container className="createAdminWrap">
        <Row>
          <Col>
            <div className="pageTitle">
              <h2>Create Admin</h2>
              <div className="cta">
                <Link to="/admins" className="button bg-secondary color-text">
                  Back to all Admins
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="card">
              <div className="cardContent withPadding">
                <form action="post" onSubmit={this.submitHandler}>
                  <input
                    onChange={this.changeHandler}
                    name="name"
                    className="form-control name"
                    type="text"
                    placeholder="Name"
                  />
                  <input
                    onChange={this.changeHandler}
                    name="email"
                    className="form-control email"
                    type="text"
                    placeholder="Email"
                  />
                  <input
                    onChange={this.changeHandler}
                    name="password"
                    className="form-control password"
                    type="text"
                    placeholder="Password"
                  />
                  <input
                    className="button bg-accent"
                    type="submit"
                    value="Submit"
                  />
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

CreateAdmin.propTypes = {
  auth: PropTypes.object.isRequired,
  createAdmin: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { createAdmin, setInfo })(
  withRouter(CreateAdmin)
);
