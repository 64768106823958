import React from "react";
import { Container, Alert } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser } from "../../inc/actions/authActions";
import { setError } from "../../inc/actions/errorActions";
import { PropTypes } from "prop-types";
import { checkMinLength, checkEmail } from "../../inc/Validation";
import store from "../../inc/store";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: "",
      isLoggedIn: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps.auth.isAuthenticated) {
      nextProps.history.push("/orders");
    }
    if (nextProps !== prevProps) {
      if (nextProps.errors.loginError) {
        return {
          ...prevProps,
          error: nextProps.errors.loginError
        };
      }
      return {
        ...prevProps
      };
    }
  }

  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validate = () => {
    var errorMsg = "";
    const { email, password } = this.state;
    if (!email || !checkEmail(email)) {
      errorMsg = "Invalid Email/Password";
      store.dispatch(
        setError({
          for: "loginError",
          msg: errorMsg
        })
      );
      return false;
    }
    if (!password || !checkMinLength(password, 6)) {
      errorMsg = "Invalid Email/Password";
      store.dispatch(
        setError({
          for: "loginError",
          msg: errorMsg
        })
      );
      return false;
    }
    return true;
  };
  submitHandler = e => {
    e.preventDefault();
    const { email, password } = this.state;
    if (this.validate()) {
      const setUser = {
        email,
        password
      };
      this.props.loginUser(setUser, this.props.history);
    } else {
      return false;
    }
  };
  errorAlert = e => {
    if (this.state.error !== "") {
      return <Alert variant="danger">{this.state.error}</Alert>;
    } else {
      return "";
    }
  };
  render() {
    return (
      <React.Fragment>
        <Container className="fullscreenCenter vh85">
          <div className="loginWrap">
            <div className="brandIcon">
              <p className="label">Admin Panel</p>
            </div>
            <br />
            {this.errorAlert()}
            <form action="post" onSubmit={this.submitHandler}>
              <input
                onChange={this.changeHandler}
                name="email"
                className="form-control email"
                type="text"
                placeholder="Email"
                value={this.state.email}
              />
              <input
                onChange={this.changeHandler}
                name="password"
                className="form-control password"
                type="password"
                placeholder="Password"
                value={this.state.password}
              />
              <input className="button bg-accent" type="submit" value="Login" />
            </form>
          </div>
          <div className="spacer"></div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  info: state.info,
  errors: state.errors
});

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

export default connect(mapStateToProps, { loginUser })(
  withRouter(Login)
);
