import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import DataTable from "react-data-table-component";
import Moment from "react-moment";
import { setInfo } from "../../inc/actions/infoActions";

import { getAdminUsers, deletedata } from "../../inc/actions/adminActions";

class AdminUsersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adminusers: [],
      auth: "",
    };
    this.props.getAdminUsers();
    this.props.setInfo({
      for: "currentPage",
      data: "admins",
    });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    let update = {};
    if (nextProps.auth) {
      if (!nextProps.auth.isAuthenticated) {
        nextProps.history.push(`/`);
      } else {
        update.auth = nextProps.auth;
      }
      if (nextProps.data) {
        if (nextProps.data.adminusers) {
          update.adminusers = nextProps.data.adminusers;
        }
      }
    } else {
      nextProps.history.push(`/`);
    }
    return update;
  }

  deleteit = (id) => {
    let confirmed = window.confirm(
      "Are you sure you want to delete this admin?"
    );
    if (confirmed) {
      this.props.deletedata("admins", id);
    }
    return false;
  };

  render() {
    const columns = [
      {
        name: "ID",
        selector: "_id",
        sortable: true,
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
      },
      {
        name: "Created On",
        selector: "date",
        sortable: true,
        cell: (row) => <Moment>{row.expiryOn}</Moment>,
      },
      {
        name: "Actions",
        cell: (row) => {
          if (this.state.auth) {
            if (this.state.auth.user._id !== row._id) {
              return (
                <span
                  style={{
                    color: "var(--accent-color)",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                  onClick={() => {
                    this.deleteit(row._id);
                    return false;
                  }}
                >
                  Delete Admin
                </span>
              );
            }
          }
        },
      },
    ];
    return (
      <React.Fragment>
        <Container className="adminusersList">
          <Row>
            <Col>
              <div className="pageTitle">
                <h2>Admin Users</h2>
                <div className="cta">
                  <Link
                    to="/admins/create/"
                    className="button bg-secondary color-text"
                  >
                    Create Admin
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable
                data={this.state.adminusers}
                columns={columns}
                theme="dark"
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.data,
});

AdminUsersList.propTypes = {
  auth: PropTypes.object.isRequired,
  getAdminUsers: PropTypes.func.isRequired,
  deletedata: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { getAdminUsers, deletedata, setInfo })(
  withRouter(AdminUsersList)
);
